import { Typography } from 'antd'
import { 
  ArrowRightOutlined, 
} from "@ant-design/icons"
import './flowStep.scss'
import StepType from '../enums/stepType.enum'
import { MultipleInput } from '../stepTypes/multipleInput/multipleInput.component'
import { SingleInput } from '../stepTypes/singleInput/singleInput.component'
import { SingleSelect } from '../stepTypes/singleSelect/singleSelect.component'
import { MultipleSelect } from '../stepTypes/multipleSelect/multipleSelect.component'
import { Static } from '../stepTypes/static/static.component'
import { Custom } from '../stepTypes/custom/custom.component'
import { Location } from '../stepTypes/location/location.component'
import { Pay } from '../stepTypes/pay/pay.component'
import { ImageUpload } from '../stepTypes/imageUpload/imageUpload.component'
import { ProductSelect } from '../stepTypes/productSelect/productSelect.component'
import { Calendly } from '../stepTypes/calendly/calendly.component'
import Breakpoint from '../../../enums/breakpoint.enum'
import { Schedule } from '../stepTypes/schedule/schedule.component'
import { Invite } from '../stepTypes/invite/invite.component'
import { Upsell } from '../stepTypes/upsell/upsell.component'
import { Upload } from '../stepTypes/upload/upload.component'
import { Facility } from '../stepTypes/facility/facility.component'
import { GiftSelect } from '../stepTypes/giftSelect/giftSelect.component'
import { GiftDetails } from '../stepTypes/giftDetails/giftDetails.component'
import { MultipleUpload } from '../stepTypes/multipleUpload/multipleUpload.component'
import { InsuranceUpload } from '../stepTypes/insuranceUpload/insuranceUpload.component'
import { PackageSelect } from '../stepTypes/packageSelect/packageSelect.component'
import useWidth from '../../../hooks/useWidth.hook'

const { Text, Paragraph } = Typography

export const FlowStep = ({
  skeleton,
  flow,
  setFlow,
  step,
  onSubmit,
  form,
  index,
  isSubmitting,
  hasAttempt,
  onNextStep,
  onPrevStep,
  cards,
  setCards,
  activeCard,
  setActiveCard,
  productTypeCode,
}) => {
  const width = useWidth()
  
  return (
    <div className="flow-step">
      {step.header && (
        <div className="flow-step-prefix">
          {step.header()}
        </div>
      )}

      {step.type === StepType.CUSTOM ? (
        <Custom
          step={step}
          form={form}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      ) : (
        <div 
          className="flow-step-dynamic"
          style={{
            margin: width > Breakpoint.SM ? '75px auto' : '40px auto'
          }}
        >
          <div className="flow-step-header">
            {index > 0 && (
              <div className="flow-step-count">
                <Text className="flow-step-index">
                  {index}
                </Text>

                <ArrowRightOutlined className="flow-step-arrow" />
              </div>
            )}

            <Paragraph className="flow-step-title">
              {typeof step?.title === 'function' ? step?.title() : step?.title}

            </Paragraph>

            {step?.description && (
              <Paragraph className="flow-step-description">
                {(step.isLocked && step.isLocked()) ? (
                  <div className="locked-description">
                    You can't change your selection for this order since you already paid. Click OK to move forward.
                  </div>
                ) : typeof step.description === 'function' ? step.description() : step.description}
              </Paragraph>
            )}
          </div>

          <div className="flow-step-body">
            {step.type === StepType.STATIC && (
              <Static
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
              />
            )}

            {step.type === StepType.SCHEDULE && (
              <Schedule
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
                step={step}
              />
            )}

            {step.type === StepType.FACILITY && (
              <Facility
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
              />
            )}

            {step.type === StepType.INVITE && (
              <Invite
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
                setFlow={setFlow}
                step={step}
              />
            )}

            {step.type === StepType.CALENDLY && (
              <Calendly
                flow={flow}
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
              />
            )}

            {step.type === StepType.MULTIPLE_INPUT && (
              <MultipleInput
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                hasAttempt={hasAttempt}
                skeleton={skeleton}
                flow={flow}
              />
            )}

            {step.type === StepType.SINGLE_INPUT && (
              <SingleInput
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                hasAttempt={hasAttempt}
                skeleton={skeleton}
                flow={flow}
              />
            )}

            {step.type === StepType.TEXTAREA && (
              <SingleInput
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                hasAttempt={hasAttempt}
                skeleton={skeleton}
                textarea={true}
                flow={flow}
              />
            )}

            {step.type === StepType.SINGLE_SELECT && (
              <SingleSelect
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
              />
            )}

            {step.type === StepType.MULTIPLE_SELECT && (
              <MultipleSelect
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
              />
            )}

            {step.type === StepType.PRODUCT_SELECT && (
              <ProductSelect
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onNextStep={onNextStep}
              />
            )}

            {step.type === StepType.GIFT_SELECT && (
              <GiftSelect 
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
              />
            )}


            {step.type === StepType.PACKAGE_SELECT && (
              <PackageSelect 
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
              />
            )}

            {step.type === StepType.GIFT_DETAILS && (
              <GiftDetails
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                flow={flow}
              />
            )}

            {step.type === StepType.UPSELL && (
              <Upsell
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onNextStep={onNextStep}
              />
            )}

            {step.type === StepType.LOCATION && (
              <Location
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                hasAttempt={hasAttempt}
              />
            )}

            {step.type === StepType.IMAGE_UPLOAD && (
              <ImageUpload
                flow={flow}
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
              />
            )}

            {step.type === StepType.MULTIPLE_UPLOAD && (
              <MultipleUpload
                form={form}
                flow={flow}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onNextStep={onNextStep}
              />
            )}

            {step.type === StepType.INSURANCE_UPLOAD && (
              <InsuranceUpload
                form={form}
                flow={flow}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onNextStep={onNextStep}
                step={step}
              />
            )}


            {step.type === StepType.UPLOAD && (
              <Upload
                form={form}
                flow={flow}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onNextStep={onNextStep}
                step={step}
              />
            )}

            {(step.type === StepType.PAY || productTypeCode) && (
              <div style={{ display: step.type === StepType.PAY ? 'block' : 'none' }}>
                <Pay
                flow={flow}
                setFlow={setFlow}
                step={step}
                form={form}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
                onNextStep={onNextStep}
                onPrevStep={onPrevStep}
                cards={cards}
                setCards={setCards}
                  activeCard={activeCard}
                  setActiveCard={setActiveCard}
                  productTypeCode={productTypeCode}
                />
              </div>
            )}

          </div>
        </div>
      )}
    </div>
  )
}