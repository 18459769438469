import { Panel, MedicalCondition, Condition, RiskLevel, ReportSectionStatus, LowercaseTest, TestCode, Supplement, Diet, ReportSection, Medication, QuestionTemplateCode, MembershipTypeCode, ReportStatus } from "../enums/index.enum"
import RecommendationData from "../data/recommendation.data";
import MembershipHelper from "./membership.helper"
import { Product } from "../data/product.data";
import TestHelper from "./test.helper";

const ReportHelper = {
  isApproved: (report) => {
    return report?.status === ReportStatus.APPROVED
  },
  isFixed: (report, sectionCode) => {
    const section = report.sections.find(({ code }) => code === sectionCode)
    return section?.status === ReportSectionStatus.FIXED || section?.suboptimalTests?.length === 0
  },
  isBetter: (report, sectionCode) => {
    return (
      report.sections.find(({ code }) => code === sectionCode)?.status ===
      ReportSectionStatus.IMPROVED
    );
  },
  isWorse: (report, sectionCode) => {
    return (
      report.sections.find(({ code }) => code === sectionCode)?.status ===
      ReportSectionStatus.WORSENED
    );
  },
  isStatic: (report, sectionCode) => {
    return (
      report.sections.find(({ code }) => code === sectionCode)?.status ===
      ReportSectionStatus.STATIC
    );
  },
  getAnswer: (report, questionTemplateCode) => {
    return report.result?.answers.sort((a, b) => {
      return new Date(b.createdAt).getTime()  - new Date(a.createdAt).getTime()
    }).find(
      ({ question }) => question.questionTemplate.code === questionTemplateCode
    )?.content;
  },
  getHealthScoreRisk: (report) => {
    if (!report) return null;
    const { healthScore } = report;
    if (typeof healthScore !== "number") return null;
    return TestHelper.getLongevityRisk(healthScore)
  },
  getTestValue: (testCode, report, results, tests) => {
    const resultValue = ReportHelper.getResultValue(
      testCode,
      report,
      results,
      tests
    );
    if (!resultValue) return null;
    return resultValue.value;
  },
  getTestRisk: (testCode, report, results, tests) => {
    const resultValue = ReportHelper.getResultValue(
      testCode,
      report,
      results,
      tests
    );
    if (!resultValue) return null;
    return resultValue.risk;
  },
  getResultValue: (testCode, report, results, tests) => {
    const test = tests?.find(({ code }) => code === testCode);
    if (!test) return null;

    let filteredResults = results
      .filter((result) => {
        return (
          new Date(result.collectedAt).getTime() <=
            new Date(report.result.collectedAt) &&
          result.values.some((value) => value.value && value.test === test._id)
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
        );
      });
    if (!filteredResults?.length) return null;
    return filteredResults[0].values.find((value) => value.test === test._id);
  },
  getTestRiskRange: (testCode, riskLevel, tests) => {
    return tests
      .find(({ code }) => code === testCode)
      ?.risk.find(({ level }) => level === riskLevel);
  },
  isGreaterThan: (testCode, riskLevel, report, results, tests) => {
    const value = ReportHelper.getResultValue(testCode, report, results, tests)?.value;
    if (value === undefined) return undefined;

    const riskRange = ReportHelper.getTestRiskRange(testCode, riskLevel, tests);
    if (riskRange === undefined) return undefined;

    const { lessThan, lessThanOrEqual } = riskRange;
    if (lessThan) return value >= lessThan;
    if (lessThanOrEqual) return value > lessThanOrEqual;
    return undefined;
  },
  hasCondition: (report, conditionCode) => {
    return report?.result?.conditions.find((code)=> {return code === conditionCode}) ? true : false
  },
  hasSection: (report, sectionCode) => {
    return report.sections.find((item)=> {return item.code === sectionCode})
  },
  hasAbnormalTrend: (code, report, results, tests) => {
    if (!tests || !report || !results || typeof code !== "number") return null;

    const test = tests.find((t) => t.code === code);

    const testResults = results
      .filter((result) => {
        return (
          new Date(result.collectedAt).getTime() <=
            new Date(report.result.collectedAt) &&
          result.values.some((value) => value.value && value.test === test._id)
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
        );
      });
    if (testResults.length < 2) return false;
    const resultOne = testResults[0];
    const riskOne = resultOne.values.find(
      (value) => value.test === test._id
    ).risk;
    const resultTwo = testResults[1];
    const riskTwo = resultTwo.values.find(
      (value) => value.test === test._id
    ).risk;
    return riskOne !== RiskLevel.OPTIMAL && riskTwo !== RiskLevel.OPTIMAL;
  },
  hasImprovingTrend: (code, report, results, tests) => {
    if (!tests || !report || !results || typeof code !== "number") return null;

    const test = tests.find((t) => t.code === code);

    const testResults = results
      .filter((result) => {
        return (
          new Date(result.collectedAt).getTime() <=
            new Date(report.result.collectedAt) &&
          result.values.some((value) => value.value && value.test === test._id)
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
        );
      });
    
    // is this a redraw? then punt
    if (testResults[1]?.hasRedraw) return false

    if (testResults.length < 2) return false;
    const currentResult = testResults[0];
    const currentValue = currentResult.values.find((value) => value.test === test._id).value;
    const currentRisk = currentResult.values.find(
      (value) => value.test === test._id
    ).risk;
    const prevResult = testResults[1];
    const prevValue = prevResult.values.find(
      (value) => value.test === test._id
    ).value;
    const prevRisk = prevResult.values.find(
      (value) => value.test === test._id
    ).risk;

    // first jsut check risk level has improved
    const riskValue = {
      [RiskLevel.OPTIMAL]: 0,
      [RiskLevel.MODERATE]: 1,
      [RiskLevel.ABNORMAL]: 1,
      [RiskLevel.HIGH]: 2,
    };
    if (!prevValue) return null;
    if (currentRisk !== prevRisk)
      return riskValue[currentRisk] < riskValue[prevRisk];


    // if risk level is the same, check if value has improved
    const optimal = test.risk.find(({ level }) => level === RiskLevel.OPTIMAL);
    if (!optimal) return null;

    const optimalMin = optimal.greaterThan || optimal.greaterThanOrEqual || 0;
    const optimalMax = optimal.lessThan || optimal.lessThanOrEqual;
  
    // If test only has a minimum threshold
    if (!optimalMax) {
      return currentValue > prevValue;
    }
  
    // If both values are within the optimal range
    const currentInRange = currentValue >= optimalMin && currentValue <= optimalMax;
    const prevInRange = prevValue >= optimalMin && prevValue <= optimalMax;
    
    if (currentInRange && prevInRange) {
      // For tests that are better when higher (like Vitamin B12)
      if (optimalMax > optimalMin * 2) {
        return currentValue > prevValue;
      }
      // For tests that are better when closer to middle of range
      const optimalMiddle = (optimalMin + optimalMax) / 2;
      const currentDistanceFromMiddle = Math.abs(currentValue - optimalMiddle);
      const prevDistanceFromMiddle = Math.abs(prevValue - optimalMiddle);
      return currentDistanceFromMiddle < prevDistanceFromMiddle;
    }
  
    // If moving into optimal range from outside
    if (currentInRange && !prevInRange) {
      return true;
    }
  
    // If both values are outside optimal range
    if (!currentInRange && !prevInRange) {
      if (prevValue < optimalMin) {
        // If below range, improving means getting higher
        return currentValue > prevValue;
      } else {
        // If above range, improving means getting lower
        return currentValue < prevValue;
      }
    }
  
    return false;

  },
  hasWorseningTrend: (code, report, results, tests) => {
    if (!tests || !report || !results || typeof code !== "number") return null;

    const test = tests.find((t) => t.code === code);

    const testResults = results
      .filter((result) => {
        return (
          new Date(result.collectedAt).getTime() <=
            new Date(report.result.collectedAt) &&
          result.values.some((value) => value.value && value.test === test._id)
        );
      })
      .sort((a, b) => {
        return (
          new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime()
        );
      });

    if (testResults.length < 3) return false; // 3 data points in a row going wrong direction
    const currentResult = testResults[0];
    const currentValue = currentResult.values.find(
      (value) => value.test === test._id
    ).value;
    const currentRisk = currentResult.values.find(
      (value) => value.test === test._id
    ).risk;
    const prevResult = testResults[1];
    const prevValue = prevResult.values.find(
      (value) => value.test === test._id
    ).value;
    const prevRisk = prevResult.values.find(
      (value) => value.test === test._id
    ).risk;

    const riskValue = {
      [RiskLevel.OPTIMAL]: 0,
      [RiskLevel.MODERATE]: 1,
      [RiskLevel.ABNORMAL]: 1,
      [RiskLevel.HIGH]: 2,
    };
    if (!prevValue) return null;
    if (currentRisk !== prevRisk)
      return riskValue[currentRisk] > riskValue[prevRisk];

    const optimal = test.risk.find(({ level }) => level === RiskLevel.OPTIMAL);
    if (!optimal) return null;

    const { lessThan, lessThanOrEqual, greaterThan, greaterThanOrEqual } =
      optimal;

    const optimalMin = greaterThan || greaterThanOrEqual || 0;
    const optimalMax = lessThan || lessThanOrEqual;

    const currentDiff =
      Math.abs(currentValue - optimalMin) > Math.abs(currentValue - optimalMax)
        ? Math.abs(currentValue - optimalMin)
        : Math.abs(currentValue - optimalMax);
    const prevDiff =
      Math.abs(prevValue - optimalMin) > Math.abs(prevValue - optimalMax)
        ? Math.abs(prevValue - optimalMin)
        : Math.abs(prevValue - optimalMax);
    return currentDiff > prevDiff;
  },
  isSuperHealthy: (report) => {
    const conditions = [
      Condition.SEVERE_HYPERTENSION,
      Condition.HYPERTENSION,
      Condition.ELEVATED_BLOOD_PRESSURE,
      Condition.SEVERE_HYPERTRIGLYCERIDEMIA,
      Condition.HYPERTRIGLYCERIDEMIA,
      Condition.ELEVATED_TRIGYLCERIDES,
      Condition.FAMILIAL_CHYLOMICRONEMIA_SYNDROME,
      Condition.INSULIN_RESISTANCE,
      Condition.LOW_VITAMIN_D,
      Condition.LOW_VITAMIN_B12,
      Condition.SUBOPTIMAL_CHOLESTEROL,
      Condition.HYPERLIPIDEMIA,
      Condition.HYPERURICEMIA,
      Condition.HYPERHOMOCYSTEINEMIA,
      Condition.ELEVATED_HOMOCYSTEINE,
      Condition.HYPERCHOLESTEROLEMIA,
      Condition.VASCULAR_INFLAMMATION,
      Condition.HIGH_RISK_10_YEAR_CVD,
      Condition.VERY_HIGH_RISK_10_YEAR_CVD,
      Condition.MODERATE_10_YEAR_CVD,
      Condition.PRE_DIABETES,
      Condition.POSSIBLE_DIABETES,
      Condition.METABOLIC_SYNDROME,
      Condition.HYPERTHYROIDISM,
      Condition.HYPOTHYROIDISM,
      Condition.HIGH_HS_CRP,
      Condition.HIGH_WBC_COUNT,
      Condition.LOW_WBC_COUNT,
      Condition.HIGH_FERRITIN,
      Condition.LOW_FERRITIN,
      Condition.CHRONIC_INFLAMMATION,
      Condition.LIVER_DISEASE,
      Condition.ANEMIA,
      Condition.RENAL_DISEASE,
      Condition.HYPOTENSION,
      Condition.PRIMARY_HYPOGONADISM,
      Condition.TESTOSTERONE_DEFICIENCY,
      Condition.CHRONIC_INFLAMMATION_MODERATE,
      Condition.CHRONIC_INFLAMMATION_HIGH,
      Condition.VASCULAR_INFLAMMATION_HIGH,
      Condition.SEVERE_ANEMIA,
      Condition.IRON_DEFICIENCY_ANEMIA_MALE,
      Condition.IRON_DEFICIENCY_ANEMIA_FEMALE,
      Condition.MODERATE_ANEMIA,
      Condition.MILD_ANEMIA,
      Condition.RENAL_DISEASE_SEVERE,
      Condition.RENAL_DISEASE_MODERATE,
      Condition.SECONDARY_HYPOGONADISM,
      Condition.LIVER_DISEASE_SEVERE,
      Condition.NAFLD_METABOLIC_SYNDROME,
      Condition.NAFLD_METABOLIC_SYNDROME_LIKE,
      Condition.LIVER_DISEASE_MODERATE,
      Condition.LIVER_DISEASE_BORDERLINE,
      Condition.DIABETES,
      Condition.THYROID_ISSUE,
      Condition.LOW_GRIP_STRENGTH,
    ];
    return !report?.result.conditions.some((condition) =>
      conditions.includes(condition)
    );
  },
  isNotOptimal: (testCode, report, results, tests) => {
    return ReportHelper.getTestRisk(testCode, report, results, tests) !== RiskLevel.OPTIMAL
  },
  getSectionImprovedTests: (report, sectionCode, tests) => {
    const section = report.sections.find((section) => section.code === sectionCode);

    if (!section?.improvedTests) return null;

    const improvedTests = section?.improvedTests.map((sectionTest) => tests.find(({ _id }) => _id === sectionTest))
    return improvedTests
  },
  getSectionStaticTests: (report, sectionCode, tests) => {
    const section = report.sections.find((section) => section.code === sectionCode);

    if (!section?.staticTests) return null;

    const staticTests = section?.staticTests.map((sectionTest) => tests.find(({ _id }) => _id === sectionTest))
    return staticTests
  },
  getSectionWorseTests: (report, sectionCode, tests) => {
    const section = report.sections.find((section) => section.code === sectionCode);

    if (!section?.worseTests) return null;

    const worseTests = section?.worseTests.map((sectionTest) => tests.find(({ _id }) => _id === sectionTest))
    return worseTests
  },
  getSectionFixedTests: (report, sectionCode, tests) => {
    const section = report.sections.find((section) => section.code === sectionCode);

    if (!section?.fixedTests) return null;

    const fixedTests = section?.fixedTests.map((sectionTest) => tests.find(({ _id }) => _id === sectionTest))
    return fixedTests
  },
  getSectionSuboptimalTests: (report, sectionCode, tests) => {

    // TO DO: port over to sending all tests into the report and filtering risk ones here
    const section = report.sections.find((section) => section.code === sectionCode);
    if (!section?.suboptimalTests) return null
    const suboptimalTests = section?.suboptimalTests.map((sectionTest) => tests.find(({ _id }) => _id === sectionTest))
    return suboptimalTests
    
  },
  getSectionTests: (report, sectionCode, tests) => {
    const section = report.sections.find(
      (section) => section.code === sectionCode
    );
        
    return Array.from(new Set(section?.tests)).map((sectionTest) =>
      tests.find(({ _id }) => _id === sectionTest)
    );
  },
  getSectionAllTests: (report, sectionCode, tests) => {
    const section = report.sections.find(
      (section) => section.code === sectionCode
    );
        
    return Array.from(new Set(section?.allTests)).map((sectionTest) =>
      tests.find(({ _id }) => _id === sectionTest)
    );
  },
  getProducts: (report, results, tests) => {

    let products = [];
    const { sections } = report;
  
    for (const section of sections) {
      if (section.show === true) {
        for (const recommendationCode of section?.recommendations) {
          const recommendation = RecommendationData.find(recommendation => recommendation.code === recommendationCode)

          let product;
          if (recommendation?.products) {
            if (typeof recommendation.products === 'function') {
              const params = ReportHelper.getRecParams(report, results, tests)
              product = Product[recommendation.products(params)[0]];
            } else {
              product = Array.isArray(recommendation.products) && recommendation.products.length > 0 ? Product[recommendation.products[0]] : null;
            }

            if (product) products.push(product)
          }

        }
  
      }
    }


    return products
 
  },
  getRecommendations: (report, sectionCode) => {
    const section = report.sections.find((section) => section.code === sectionCode);
    if (section) return section?.recommendations
    return null
  },
  getAllRecommendations: (report) => {
    const recommendations = report.sections.filter(item => item.show === true).map(item => item.recommendations).flat();
    return recommendations
  },
  getPlan: (report) => {
    return report?.result?.order?.plan
  },
  getSectionIndex: (report, sectionCode) => {
    // Filter the sections to include only those where 'show' is true
    const visibleSections = report.sections.filter(section => section.show);

    // Find the section with the matching code in the filtered list
    const section = visibleSections.find(section => section.code === sectionCode);

    // Return the index of the found section in the filtered list, or null if not found
    return section ? visibleSections.indexOf(section) : null;
  },
  getTestNames: (tests) => {
    const testNames = tests.map((test, index) => (
      <>
      <span key={test._id}>{LowercaseTest[test.code]}</span>
      {tests.length > 2 && index < tests.length - 1 && ', '}
      {tests.length > 1 && index === tests.length - 2 && ' and '}
      </>
    ));

    return testNames
  },
  isCloseToOptimal: (testCode, report, results, tests) => {

    const testValue = ReportHelper.getTestValue(testCode, report, results, tests)
    const optimalRange = ReportHelper.getTestRiskRange(testCode, RiskLevel.OPTIMAL, tests)

    const optimalThreshold1 = optimalRange.greaterThan || optimalRange.greaterThanOrEqual
    // const optimalThreshold2 = optimalRange.lessThan || optimalRange.lessThanOrEqual

    const diff1 = (Math.abs(testValue-optimalThreshold1)/optimalThreshold1) 
    // const diff2 = (Math.abs(testValue-optimalThreshold2)/optimalThreshold2) 

    if (diff1 <0.05) return true

    return false;

  },
  getRiskEval: (testCode, report, results, tests) => {

    let riskLevel;

    if (testCode === TestCode.BP) {
      const SystolicRisk = ReportHelper.getTestRisk(TestCode.BP_S, report, results, tests)
      const DiastolicRisk = ReportHelper.getTestRisk(TestCode.BP_D, report, results, tests)
      if (SystolicRisk === RiskLevel.OPTIMAL && DiastolicRisk === RiskLevel.OPTIMAL) riskLevel = RiskLevel.OPTIMAL
      else if (SystolicRisk === RiskLevel.HIGH) { riskLevel = RiskLevel.HIGH; testCode = TestCode.BP_S }
      else if (DiastolicRisk === RiskLevel.HIGH) { riskLevel = RiskLevel.HIGH; testCode = TestCode.BP_D }
      else if (SystolicRisk === RiskLevel.MODERATE) { riskLevel = RiskLevel.MODERATE; testCode = TestCode.BP_S }
      else if (DiastolicRisk === RiskLevel.MODERATE) { riskLevel = RiskLevel.MODERATE; testCode = TestCode.BP_D }
    }

    else 
      riskLevel = ReportHelper.getTestRisk(testCode, report, results, tests);

    if (riskLevel === RiskLevel.OPTIMAL)
      return "healthy"

    const greaterThan  = ReportHelper.isGreaterThan(testCode, RiskLevel.OPTIMAL, report, results, tests)

    // test specific terminology
    if (testCode === TestCode.VITAMIN_D && riskLevel === RiskLevel.MODERATE && greaterThan)
      return "a bit high"


    if (riskLevel === RiskLevel.MODERATE && ReportHelper.isCloseToOptimal(testCode, report, results, tests))
      return "not optimal"
  
    if (riskLevel === RiskLevel.MODERATE && testCode===TestCode.GRIP)
      return "not optimal"
    
    if (riskLevel === RiskLevel.MODERATE && greaterThan)
      return "elevated"

    if ([RiskLevel.ABNORMAL, RiskLevel.MODERATE].includes(riskLevel) && greaterThan) {
      if ([TestCode.DIRECT_BILIRUBIN, TestCode.HOMAIR, TestCode.BP_S, TestCode.BP_D, TestCode.URIC_ACID, TestCode.HBA1C, TestCode.CYSTATIN_C, TestCode.TSH].includes(testCode)) return "elevated" 
      else return "high"
    }
    if ([RiskLevel.ABNORMAL, RiskLevel.MODERATE].includes(riskLevel) && !greaterThan) return "low"
    if (riskLevel === RiskLevel.HIGH && greaterThan) {
      if ([TestCode.IRON_SATURATION].includes(testCode)) return "high" 
      return "too high"
    } 
    if (riskLevel === RiskLevel.HIGH && !greaterThan) return "too low" 

    return "healthy"
  },
  getRecParams: (report, results, tests, setTestId, setOpenModal) => {
    const { patient, result } = report

    const { 
      diet, 
      exercises150PerWeek,
      familyHistory,
      memberships,
      isAthlete
    } = patient

    
    const vitaminDValue = ReportHelper.getTestValue(TestCode.VITAMIN_D, report, results, tests);
    const vitaminDRisk = ReportHelper.getTestRisk(TestCode.VITAMIN_D, report, results, tests);
    const vitaminB12Value = ReportHelper.getTestValue(TestCode.VITAMIN_B12, report, results, tests);
    const vitaminB9Value = ReportHelper.getTestValue(TestCode.FOLATE, report, results, tests);
    const omega3Index = ReportHelper.getTestValue(TestCode.OMEGA_3_INDEX, report, results, tests);
    const glucoseRisk = ReportHelper.getTestRisk(TestCode.GLUCOSE, report, results, tests)
    const hba1cRisk = ReportHelper.getTestRisk(TestCode.HBA1C, report, results, tests)
    const thyroidTests = tests?.filter(({code})=> [TestCode.TSH, TestCode.FREET4, TestCode.FREET3, TestCode.TPO_ANTIBODY].includes(code))
    const currentMedications = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICATIONS)
    const currentSupplements =  ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_SUPPLEMENTS)
    const strengthTrains = ReportHelper.getAnswer(report, QuestionTemplateCode.STRENGTH)
    const hasBP =  ReportHelper.getTestRisk(TestCode.BP_S, report, results, tests);
    const isPregnant = ReportHelper.hasCondition(report, Condition.PREGNANT)


    const handleViewTest = (testCode) => {
      setTestId(tests.find((t) => t.code === testCode)._id);
      setOpenModal(true);
    };

    const recParams =  {
      // is member
      isMember: MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY) ||  MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.PREMIUM) || MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.PREMIUM_12MO) || MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY_12MO),
      isHeartHealthMember:  MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH) ||  MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.ULTIMATE), 
      isAthlete: isAthlete,

      // inflammation
      hasHighHsCrp: ReportHelper.hasCondition(report, Condition.HIGH_HS_CRP),

      // pregnant
      isPregnant: isPregnant,

      // supplements + medications
      supplementFiberAlready: currentSupplements?.includes(Supplement.VISCOUS_FIBER),
      supplementBerberineAlready: currentSupplements?.includes(Supplement.BERBERINE),
      supplementRedYeastRiceAlready: currentSupplements?.includes(Supplement.RED_YEAST_RICE),

      // vitamin d
      isTakingVitaminD3: currentSupplements?.includes(Supplement.VITAMIN_D3) || currentSupplements?.includes(Supplement.VITAMIN_D_OR_CALCIFEDIOL),
      isTakingCalcifediol: currentSupplements?.includes(Supplement.CALCIFEDIOL) || currentSupplements?.includes(Supplement.VITAMIN_D_OR_CALCIFEDIOL),
      nearOptimalVitaminD: vitaminDValue >= 30,
      optimalVitaminD: vitaminDRisk === RiskLevel.OPTIMAL,
      vitaminDIncreasing: ReportHelper.hasImprovingTrend(TestCode.VITAMIN_D, report, results, tests),
      highVitaminD: result?.conditions?.includes(Condition.HIGH_VITAMIN_D),
      lowVitaminD: result?.conditions?.includes(Condition.LOW_VITAMIN_D),

      // cardio
      familyHistoryHighCholesterol: familyHistory?.includes(MedicalCondition.HIGH_CHOLESTEROL),
      statinAlready: [Medication.CHOLESTEROL, Medication.INCLISIRIN, Medication.STATIN, Medication.EZETIMIBE].some(med => currentMedications?.includes(med)),
      supplementOmega3sAlready: currentSupplements?.includes(Supplement.OMEGA_3S),
      isTakingBVitamins: [Supplement.VITAMIN_B12, Supplement.VITAMIN_B9, Supplement.VITAMIN_B6].every(supplement => currentSupplements?.includes(supplement)),
      supplementB12Already: currentSupplements?.includes(Supplement.VITAMIN_B12),
      supplementB9Already: currentSupplements?.includes(Supplement.VITAMIN_B9),
      supplementB6Already: currentSupplements?.includes(Supplement.VITAMIN_B6),
      lowVitaminB12: ReportHelper.hasCondition(report, Condition.LOW_VITAMIN_B12),
      lowVitaminB9: ReportHelper.hasCondition(report, Condition.LOW_VITAMIN_B9), 
      hasSIBO: ReportHelper.hasCondition(report, Condition.SIBO), 
      hasThalassemia: ReportHelper.hasCondition(report, Condition.THALASSEMIA),
      hasMacrocytosis: ReportHelper.hasCondition(report, Condition.MACROCYTOSIS),
      isMeatEater: !diet?.includes(Diet.VEGAN) && !diet?.includes(Diet.VEGETARIAN),
      diet: diet,
      isVegan: diet?.includes(Diet.VEGAN),
      isVegetarian: diet?.includes(Diet.VEGETARIAN),
      TG: parseFloat(ReportHelper.getTestValue(TestCode.TG, report, results, tests)),
      b12Up: ReportHelper.hasImprovingTrend(TestCode.VITAMIN_B12, report, results, tests),
      takingTGMedicationAlready: currentMedications?.includes(Medication.LOWER_TRIGLYCERIDES),
      hasCardioIssue: ReportHelper.hasSection(report, ReportSection.CARDIO_ADVICE),
      nearOptimalVitaminB12: vitaminB12Value >=400,
      nearOptimalVitaminB9: vitaminB9Value >=15,
      omega3Index: omega3Index,
      hasBP: hasBP,
      hasHighCAC: ReportHelper.hasCondition(report, Condition.HIGH_CAC),

      // metabolic
      exercises150PerWeek: exercises150PerWeek === true || exercises150PerWeek === "true",
      lowCarbDiet : diet && diet.includes(Diet.LOW_CARB),
      takingGlucoseMedicationAlready : currentMedications && (currentMedications?.includes(Medication.LOWER_GLUCOSE) || currentMedications?.includes(Medication.GLP1) || currentMedications?.includes(Medication.METFORMIN)),
      glucoseUp: ReportHelper.hasWorseningTrend(TestCode.GLUCOSE, report, results, tests),
      isolatedHba1c: glucoseRisk === RiskLevel.OPTIMAL && hba1cRisk !== RiskLevel.OPTIMAL,
      hasMetabolicIssue: ReportHelper.hasSection(report, ReportSection.METABOLIC_ADVICE),
      isTakingMetformin: currentMedications && currentMedications?.includes(Medication.METFORMIN),
      uricAcidMedicationAlready: currentMedications && currentMedications?.includes(Medication.ALLOPURINOL),

      // bp
      isTakingBPMedication: currentMedications?.includes(Medication.HIGH_BLOOD_PRESSURE),
      optimalBP: !ReportHelper.hasCondition(report, Condition.ELEVATED_BLOOD_PRESSURE),
      strengthTrains:strengthTrains,

      hasHighCreatinine: ReportHelper.isGreaterThan(TestCode.CREATININE, RiskLevel.OPTIMAL, report, results, tests),

      // iron/anemia
      isTakingIron: currentSupplements?.includes(Supplement.IRON),
      isAnemic: ReportHelper.hasCondition(report, Condition.IRON_DEFICIENCY_ANEMIA_FEMALE) ||  ReportHelper.hasCondition(report, Condition.IRON_DEFICIENCY_ANEMIA_MALE),
      isAnemiaBetter: ReportHelper.isBetter(report, ReportSection.ANEMIA_ADVICE), 

      // thyroid
      didFullThyroidPanel: thyroidTests?.every(test=> result?.values.some(value=>value.test === test._id)),
      isTakingBiotin: currentSupplements?.includes(Supplement.BIOTIN),

      hasAbdominalObesity: ReportHelper.hasCondition(report,Condition.ABDOMINAL_OBESITY),
      isTakingTGMedication: currentMedications?.includes(Medication.LOWER_TRIGLYCERIDES),
      lowFatDietAlready: diet?.includes(Diet.LOW_FAT),

      // vitamin b12
      target: ReportHelper.hasCondition(report, Condition.HIGH_VITAMIN_B12) ? "vitamin B12" : null,

      // view
      onViewVitaminB12: () => handleViewTest(TestCode.VITAMIN_B12),
      onViewVitaminB9: () => handleViewTest(TestCode.FOLATE),
      onViewMCV: () => handleViewTest(TestCode.MCV),
      onViewCampesterol: () => handleViewTest(TestCode.CAMPESTEROL),
      onViewSitosterol: () => handleViewTest(TestCode.SITOSTEROL),

    }

    return recParams
  },
  getPanel: (report) => {
    return report?.result?.order?.panel
  },
  isAthletePanel: (report) => {
    return report?.result?.order?.panel === Panel.ATHLETE
  },
  isBloodPanel: (report) => {
    return [Panel.ADVANCED_BASELINE, Panel.ADVANCED_FOLLOWUP, Panel.ATHLETE, Panel.HEART_HEALTH].includes(report?.result?.order?.panel)
  },
  hasLongevityScore: (report) => {
    return [Panel.ADVANCED_BASELINE, Panel.ADVANCED_FOLLOWUP].includes(report?.result?.order?.panel)
  }
};

export default ReportHelper;
