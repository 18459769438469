import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Button, message, Select, Input, Row, Col } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import RuleHelper from '../../helpers/rule.helper';
import FormHelper from '../flow/helpers/form.helper';
import FlowType from '../../enums/flowType.enum';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import Org from '../../enums/org.enum';
import PhlebType from '../../enums/phlebType.enum';
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import { getAppointment, updateAppointment, addAppointment } from '../../services/appointment.service';
import { addProxyFlow, updateFlow } from '../../services/flow.service';
import { addProduct } from '../../services/product.service';
import { listPatients } from '../../services/patient.service';
import { listFacilities } from "../../services/facility.service"
import { listPhlebotomists } from '../../services/phlebotomist.service';
import { listProviders } from '../../services/provider.service';

import TimeInput from '../timeInput/timeInput.component';
import DateInput from '../dateInput/dateInput.component';
import "./adminAppointmentForm.scss"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const { Item } = Form

const flowTypes = {
  [FlowType.LONGEVITY_TEST]: {
    title: 'Comprehensive Blood Panel',
    productTypeCode: ProductTypeCode.LONGEVITY_PANEL
  },
  [FlowType.ATHLETE_TEST]: {
    title: 'Athlete Panel',
    productTypeCode: ProductTypeCode.ATHLETE_PANEL
  },
  [FlowType.CUSTOM_TEST]: {
    title: 'Custom Panel',
    productTypeCode: ProductTypeCode.CUSTOM_PANEL
  },
  [FlowType.CAC]: {
    title: 'Coronary Calcium Scan',
    productTypeCode: ProductTypeCode.CTCALCIUM
  },
  [FlowType.DEXA_BODYCOMP]: {
    title: 'Dexa Body Comp',
    productTypeCode: ProductTypeCode.DEXA_BODYCOMP
  },
  [FlowType.DEXA]: {
    title: 'Dexa Bone Density',
    productTypeCode: ProductTypeCode.DEXA_BONE
  },
  [FlowType.LONGEVITY_CONSULT]: {
    title: 'Longevity Consult',
    productTypeCode: ProductTypeCode.CONSULT_LONGEVITY
  },
  [FlowType.HEART_MEMBER_CONSULT]: {
    title: 'Heart Member Consult',
    productTypeCode: ProductTypeCode.CONSULT_HEART_HEALTH
  },
  [FlowType.ATHLETE_CONSULT]: {
    title: 'Athlete Consult',
    productTypeCode: ProductTypeCode.CONSULT_ATHLETE
  },
  [FlowType.CARDIOLOGY_CONSULT]: {
    title: 'Cardiology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_CARDIOLOGY
  },
  [FlowType.GASTROENTEROLOGY_CONSULT]: {
    title: 'Gastroenterology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_GASTROENTEROLOGY
  },
  [FlowType.DERMATOLOGY_CONSULT]: {
    title: 'Dermatology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_DERMATOLOGY
  }, 
  [FlowType.NEUROLOGY_CONSULT]: {
    title: 'Neurology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_NEUROLOGY
  },
  [FlowType.UROLOGY_CONSULT]: {
    title: 'Urology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_UROLOGY
  },
  [FlowType.GYNECOLOGY_CONSULT]: {
    title: 'Gynecology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_GYNECOLOGY
  },
  [FlowType.ENDOCRINOLOGY_CONSULT]: {
    title: 'Endocrinology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_ENDOCRINOLOGY
  },
  [FlowType.RHEUMATOLOGY_CONSULT]: {
    title: 'Rheumatology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_RHEUMATOLOGY
  },
  [FlowType.ORTHOPEDICS_CONSULT]: {
    title: 'Orthopedics Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_ORTHOPEDICS
  },
  [FlowType.DENTAL_CONSULT]: {
    title: 'Dental Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_DENTAL
  },
  [FlowType.ENDOSCOPY]: {
    title: 'Endoscopy',
    productTypeCode: ProductTypeCode.ENDOSCOPY
  },
  [FlowType.OPTHALMOLOGY_CONSULT]: {
    title: 'Ophthalmology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_OPTHALMOLOGY
  },
  [FlowType.ENT_CONSULT]: {
    title: 'ENT Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_ENT
  },
  [FlowType.LEQVIO]: {
    title: 'Leqvio Treatment',
    productTypeCode: ProductTypeCode.LEQVIO
  },
  [FlowType.CARDIAC_MRI]: {
    title: 'Cardiac MRI',
    productTypeCode: ProductTypeCode.CARDIAC_MRI
  },
  [FlowType.PLASMA_DONATION]: {
    title: 'Plasma Donation',
    productTypeCode: ProductTypeCode.PLASMA_DONATION
  },
  [FlowType.UROGYNECOLOGY_CONSULT]: {
    title: 'Urogynecology Consult',
    productTypeCode: ProductTypeCode.CONSULTATION_UROGYNECOLOGY
  }
}

// Add a helper function to determine appointment type
const isConsultFlow = (flowType) => {
  return [
    FlowType.LONGEVITY_CONSULT,
    FlowType.HEART_MEMBER_CONSULT,
    FlowType.ATHLETE_CONSULT,
    FlowType.CARDIOLOGY_CONSULT,
    FlowType.GASTROENTEROLOGY_CONSULT,
    FlowType.DERMATOLOGY_CONSULT,
    FlowType.NEUROLOGY_CONSULT,
    FlowType.UROLOGY_CONSULT,
    FlowType.GYNECOLOGY_CONSULT,
    FlowType.ENDOCRINOLOGY_CONSULT,
    FlowType.RHEUMATOLOGY_CONSULT,
    FlowType.ORTHOPEDICS_CONSULT,
    FlowType.DENTAL_CONSULT,
    FlowType.ENDOSCOPY,
    FlowType.OPTHALMOLOGY_CONSULT,
    FlowType.ENT_CONSULT,
    FlowType.LEQVIO,
    FlowType.UROGYNECOLOGY_CONSULT
  ].includes(flowType);
}

// Add helper function to check if it's a blood panel type
const isBloodPanel = (flowType) => {
  return [
    FlowType.LONGEVITY_TEST,
    FlowType.ATHLETE_TEST,
    FlowType.CUSTOM_TEST
  ].includes(flowType);
}

export const AdminAppointmentForm = ({ open, setOpen, onSuccess, appointmentId, setAppointmentId, populate=[], select, patient }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [appointment, setAppointment] = useState()
  const [patients, setPatients] = useState()
  const [phlebotomists, setPhlebotomists] = useState()
  const [facilities, setFacilities] = useState([]);
  const [hasAttempt, setHasAttempt] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt
  const [providers, setProviders] = useState()

  // Modify to handle both new and existing appointments
  const [selectedFlowType, setSelectedFlowType] = useState(null);

  useEffect(() => {
    if (open) {
      if (appointmentId) {
        setFormFields()
      } else {
        setSelectedFlowType(null)
      }
    } else {
      setAppointmentId(null)
      form.resetFields()
      setHasAttempt(false)
      setSelectedFlowType(null)
    }
  }, [open])

  useEffect(() => {

    fetchPatients()
    fetchPhlebotomists()
    fetchFacilities()
    fetchProviders()
  }, [patient])

  const fetchPatients = async () => {
    setPatients(sortUsers(await listPatients({
      select: '_id firstName lastName location',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        },
        location: {
          $ne: null
        }
      },
      sort: '-createdAt'
    })))
  }

  const fetchPhlebotomists = async () => {
    setPhlebotomists(await listPhlebotomists({
      select: '_id email firstName lastName',
      filter: {
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        },
        location: {
          $ne: null
        }
      },
      sort: 'firstName'
    }))
  }

  const fetchFacilities = async () => {
    try {
      const facilities = await listFacilities({sort: 'name'});
      setFacilities(facilities || []);
    } catch (error) {
      console.error('Failed to fetch facilities:', error);
    }
  }

  const fetchProviders = async () => {
    try {
      const providers = await listProviders({
        select: '_id firstName lastName email',
        filter: {
          firstName: { $ne: null },
          lastName: { $ne: null },
          org: Org.INSTALAB
        },
        sort: 'firstName'
      });
      setProviders(sortUsers(providers));
    } catch (error) {
      console.error('Failed to fetch providers:', error);
    }
  }

  const onCancel = () => {
    setOpen(false)
  }


  const setFormFields = async () => {
    if (appointmentId) {
      const fetchedAppointment = await getAppointment(appointmentId, {
        select: 'start phlebotomist provider patients location facility status notes externalNotes remoteLocation flow',
        populate: [{
          path: 'flow',
          select: 'type'
        }]
      })

      setAppointment(fetchedAppointment)
      // Set the flow type for field display logic
      setSelectedFlowType(fetchedAppointment.flow?.type)

      const startDate = fetchedAppointment.start
        ? dayjs(fetchedAppointment.start).tz(fetchedAppointment.location?.timeZoneId).format('MM/DD/YYYY')
        : null;

      const startTime = fetchedAppointment.start
        ? dayjs(fetchedAppointment.start).tz(fetchedAppointment.location?.timeZoneId).format('hh:mm A')
        : null;
  
      form.setFieldsValue({
        patient: fetchedAppointment.patients[0],
        startDate:  startDate || undefined,
        startTime: startTime || undefined,
        phlebotomist: fetchedAppointment.phlebotomist,
        flowType: fetchedAppointment.flow?.type,
        facility: fetchedAppointment.facility,
        status: fetchedAppointment.status,
        notes: fetchedAppointment.notes,
        externalNotes: fetchedAppointment.externalNotes,
        provider: fetchedAppointment.provider,
        remoteLocation: fetchedAppointment.remoteLocation
      })
    } else {
      form.resetFields()
    }
  }

  const sortUsers = (users) => {
    return users.sort((a, b) => {
      const nameA = a.firstName.toLowerCase();
      const nameB = b.firstName.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    
      // If first names are equal, compare last names
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();
      if (lastNameA < lastNameB) return -1;
      if (lastNameA > lastNameB) return 1;
    
      return 0; // Names are equal
    })
  }

  const onFail = () => {
    setHasAttempt(true)
    message.error('Enter valid appointment data')
  }

  const onOk = async () => {
    setIsSubmitting(true)

    try {
      let fetchedAppointment
      if (appointmentId) {
        fetchedAppointment = await onUpdateAppointment()
      } else {
        fetchedAppointment = await onAddAppointment()
      }

      if (onSuccess) {
        onSuccess(fetchedAppointment)
      }
      message.success(appointmentId ? `Updated appointment` : `Added appointment`)
      setOpen(false)
      if (!appointmentId) {
        form.resetFields()
      }
    } catch (err) {
      console.log(err)
      message.error(appointmentId ? `Failed to update appointment` : `Failed to add appointment`)
    }
    setIsSubmitting(false)
  }

  const onAddAppointment = async () => {
    const {
      flowType,
      startDate,
      startTime,
      phlebotomist,
      provider,
      facility,
      priceOverride,
      notes,
      externalNotes,
      remoteLocation,
      status
    } = form.getFieldsValue()

    // Use passed in patient prop if available, otherwise get from form
    const patientId = patient || form.getFieldValue('patient')

    // Create flow
    const flowFields = {
      type: flowType,
      user: patientId,
    }

    let flow = await addProxyFlow(flowFields)

    // Add products to proxy flow
    const response = await addProduct({
      fields: {
        flowId: flow._id,
        patient: patientId,
        type: flowTypes[flowType].productTypeCode,
        free: true,
        hasKit: false,
        hasAppointment: true,
        priceOverride: priceOverride,
        notes,
        externalNotes,
        remoteLocation
      }
    })

    // Append new product to flow
    flow = await updateFlow(flow._id, {
      products: [response.product._id]
    })

    // Create appointment
    // Set timezone based on facility or patient's location
    let timeZoneId;
    const { location } = patients.find(({ _id }) => _id === patientId);
    if (facility) {
      const selectedFacility = facilities.find(f => f._id === facility);
      timeZoneId = selectedFacility?.location?.timeZoneId || location?.timeZoneId; // Use facility timezone
    } else {
      timeZoneId = location?.timeZoneId; // Use patient's location timezone
    }


    const start =  startDate && startTime ? dayjs.tz(`${startDate} ${startTime}`, 'MM/DD/YYYY hh:mm A', timeZoneId) : null

    if (start && !start?.isValid()) {
      throw new Error('Invalid date or time format')
    }

    const newAppointment = await addAppointment({
      flow: flow._id,
      fields: {
        start,
        type: flowType,
        phlebotomist,
        provider,
        facility,
        phlebType: isBloodPanel(flowType) ? facility ? PhlebType.FACILITY : PhlebType.INSTALAB : null,
        products: [response.product._id],
        notes,
        externalNotes,
        remoteLocation,
        status
      },
      select,
      populate,
    })

    return newAppointment
  }

  const onUpdateAppointment = async () => {
    const {
      startDate,
      startTime,
      facility,
      provider,
      phlebotomist,
      status,
      notes,
      externalNotes,
      remoteLocation
    } = form.getFieldsValue()

    // Handle timezone and create start datetime only if both date and time are provided
    let start = null;
    if (startDate && startTime) {
      // Try to get timezone in this order: appointment location, facility location, or default to user's local timezone
      const timeZoneId = appointment?.location?.timeZoneId || 
                        facilities.find(f => f._id === facility)?.location?.timeZoneId || 
                        dayjs.tz.guess();

      start = dayjs.tz(`${startDate} ${startTime}`, 'MM/DD/YYYY hh:mm A', timeZoneId);

      if (!start.isValid()) {
        message.error("Invalid date or time.");
        return;
      }
    }
    
    const updatedAppointment = await updateAppointment(appointmentId, {
      fields: {
        start,
        facility,
        provider,
        phlebotomist,
        status,
        notes,
        externalNotes,
        remoteLocation
      },
      select,
      populate,
    })

    return updatedAppointment
  }

  return (
    <Modal 
      open={open} 
      title={appointmentId ? `Edit Appointment` : <>Add Appointment</>}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={<center><b>Note: This is not connected to Getlabs booking process.</b></center>}
      className="admin-appointment-modal"
    >
      <Form
        form={form}
        onFinish={onOk}
        onFinishFailed={onFail}
        layout='vertical'
        className="admin-appointment-form"
      >
        {/* Show patient selection only for new appointments */}
        {!appointmentId && !patient && (
          <Item 
            label="Patient" 
            name="patient"
            rules={[RuleHelper.isRequired]}
            validateTrigger={[]}
          >
            <Select 
              placeholder="Select patient..."
              options={patients?.map(patient => ({
                label: `${patient.firstName} ${patient.lastName}`,
                value: patient._id
              }))}
              onChange={() => {
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form)
                }
              }}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              className="single-select"
              showSearch
            />
          </Item>
        )}

        {/* Show appointment type selection only for new appointments */}
        {!appointmentId && (
          <Item 
            label="Appointment Type"
            name="flowType"
            rules={[RuleHelper.isRequired]}
            validateTrigger={[]}
          >
            <Select 
              placeholder="Select appointment type..."
              onChange={(value) => {
                setSelectedFlowType(value);
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form);
                }
              }}
              options={Object.entries(flowTypes)
                .sort((a, b) => a[1].title.localeCompare(b[1].title))
                .map(([flowType, values]) => ({
                  label: values.title,
                  value: flowType
                }))}
              showSearch
              optionFilterProp="label"
              filterOption={(input, option) => 
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
            />
          </Item>
        )}

        {/* Show all other fields including notes only when we have a selectedFlowType */}
        {selectedFlowType && (
          <>
            {isConsultFlow(selectedFlowType) ? (
              // Consult appointment fields
              <>
              <Item label="Provider" name="provider">
                  <Select 
                    placeholder="Select provider..."
                    options={providers?.map(provider => ({
                      label: `${provider.firstName} ${provider.lastName}`,
                      value: provider._id
                    }))}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option?.label?.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Item>
                <Item label="Remote Location" name="remoteLocation">
                  <Input placeholder="https://" />
                </Item>
                <Item label="Facility" name="facility">
                  <Select 
                    placeholder="Select facility..."
                    options={facilities?.map(facility => ({
                      label: facility.name,
                      value: facility._id
                    }))}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option?.label?.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Item>

                <Item label="Appointment Date" name="startDate">
                  <DateInput />
                </Item>
                <Item label="Appointment Time" name="startTime">
                  <TimeInput />
                </Item>
                <Item label="Status" name="status">
                  <Select
                    placeholder="Select status..."
                    options={Object.entries(AppointmentStatus).map(([key, value]) => ({
                      label: key,
                      value: value
                    }))}
                  />
                </Item>
                {!appointmentId && (
                  <Item label="Price Override" name="priceOverride">
                    <Input placeholder="Price" type="text" />
                  </Item>
                )}  
              </>
            ) : (
              // Test appointment fields
              <>
                <Item label="Facility" name="facility">
                  <Select 
                    placeholder="Select facility..."
                    options={facilities?.map(facility => ({
                      label: facility.name,
                      value: facility._id
                    }))}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) => 
                      option?.label?.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Item>
                {isBloodPanel(selectedFlowType) && (
                  <Item label="Phlebotomist" name="phlebotomist">
                    <Select 
                      placeholder="Select phlebotomist..."
                      options={phlebotomists?.map(phleb => ({
                        label: `${phleb.firstName} ${phleb.lastName}`,
                        value: phleb._id
                      }))}
                      showSearch
                      optionFilterProp="label"
                      filterOption={(input, option) => 
                        option?.label?.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Item>
                )}

                <Item label="Appointment Date" name="startDate">
                  <DateInput />
                </Item>
                <Item label="Appointment Time" name="startTime">
                  <TimeInput />
                </Item>
                <Item label="Status" name="status">
                  <Select
                    placeholder="Select status..."
                    options={Object.entries(AppointmentStatus).map(([key, value]) => ({
                      label: key,
                      value: value
                    }))}
                  />
                </Item>
                {!appointmentId && (
                  <Item label="Price Override" name="priceOverride">
                    <Input placeholder="Price" type="text" />
                  </Item>
                )}  
              </>
            )}

            <Row gutter={16}>
              <Col span={12}>
                <Item 
                  label="Internal Notes"
                  name="notes"
                  help="Only Instalab staff can see these notes"
                >
                  <Input.TextArea 
                    placeholder="Notes" 
                    rows={3} 
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Item>
              </Col>
              <Col span={12}>
                <Item 
                  label="External Notes"
                  name="externalNotes"
                  help="Notes that patient can see"
                >
                  <Input.TextArea 
                    placeholder="Notes" 
                    rows={3} 
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Item>
              </Col>
            </Row>

            <Item>
              <Button
                className="submit-btn"
                type='primary'
                htmlType='submit'
                loading={isSubmitting}
              >
                {appointmentId ? 'Update Appointment' : 'Create Appointment'}
              </Button>
            </Item>
          </>
        )}
      </Form>
    </Modal>
  )
}
